<template>
  <div>
    <v-card flat>
      <v-app-bar color="primary" short dense dark flat v-if="session">
        <div>
          <v-tabs v-model="tab_title" right dark background-color="primary">
            <v-tab href="#tab-rj">
              <h5>Riwayat Jabatan</h5>
            </v-tab>

            <!-- <v-tab href="#tab-rjd">
              <h5>Riwayat Jabatan Definitive</h5>
            </v-tab> -->

            <v-tab href="#tab-rjt">
              <h5>Riwayat Jabatan Tambahan</h5>
            </v-tab>

            <v-tab href="#tab-rjjf">
              <h5>Riwayat Jabatan JU/JF</h5>
            </v-tab>
          </v-tabs>
        </div>

        <v-spacer></v-spacer>

        <div
          class="mr-3 mt-1"
          v-if="
            session.authorities.some((item) => item === 'CRUD_RWJAB') &&
            tab_title == 'tab-rj'
          "
        >
          <rwjabatanModalAdd />
        </div>

        <!-- <div
          class="mr-3 mt-1"
          v-if="
            session.authorities.some((item) => item === 'CRUD_JABDEF') &&
            tab_title == 'tab-rjd'
          "
        >
          <jdAdd />
        </div> -->

        <div
          class="mr-3 mt-1"
          v-if="
            session.authorities.some((item) => item === 'CRUD_JABTAM') &&
            tab_title == 'tab-rjt'
          "
        >
          <jtAdd />
        </div>

        <div
          class="mr-3 mt-1"
          v-if="
            session.authorities.some((item) => item === 'CRUD_JABJF') &&
            tab_title == 'tab-rjjf'
          "
        >
          <jfAdd />
        </div>

        <div style="max-width: 300px">
          <v-tabs v-model="tab" right dark background-color="primary">
            <v-tab href="#tab-1" @click="getSIMASN()">
              <h5>IDASN</h5>
            </v-tab>

            <v-tab
              href="#tab-2"
              @click="getSAPK()"
              :disabled="tab_title != 'tab-rj'"
            >
              <h5>SIASN</h5>
            </v-tab>
          </v-tabs>
        </div>
      </v-app-bar>

      <v-tabs-items v-model="tab" touchless v-if="tab_title == 'tab-rj'">
        <v-tab-item value="tab-1" class="pa-5">
          <rwjabatanSIMASN />
        </v-tab-item>
        <v-tab-item value="tab-2" class="pa-5">
          <rwjabatanSAPK />
        </v-tab-item>
      </v-tabs-items>

      <!-- <v-tabs-items v-model="tab_title" touchless v-if="tab_title == 'tab-rjd'">
        <v-tab-item value="tab-rjd" class="pa-5">
          <rwjabdef />
        </v-tab-item>
      </v-tabs-items> -->

      <v-tabs-items v-model="tab_title" touchless v-if="tab_title == 'tab-rjt'">
        <v-tab-item value="tab-rjt" class="pa-5">
          <rwjabtam />
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items
        v-model="tab_title"
        touchless
        v-if="tab_title == 'tab-rjjf'"
      >
        <v-tab-item value="tab-rjjf" class="pa-5">
          <rwjabjf />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getData from "@/store/asn/rwjabatan/tabs";

import rwjabatanSIMASN from "@/components/asn/profil/rwjabatan/rwjabatan";
import rwjabatanSAPK from "@/components/asn/profil/rwjabatan/SAPK_rwjabatan_Modal";
import rwjabatanModalAdd from "@/components/asn/profil/rwjabatan/ModalAdd";

// import rwjabdef from "@/components/asn/profil/rwjabdef/data";
// import jdAdd from "@/components/asn/profil/rwjabdef/ModalAdd";

import rwjabtam from "@/components/asn/profil/rwjabtam/data";
import jtAdd from "@/components/asn/profil/rwjabtam/ModalAdd";

import rwjabjf from "@/components/asn/profil/rwjabjf/data";
import jfAdd from "@/components/asn/profil/rwjabjf/ModalAdd";

export default {
  name: "DataUtama",

  components: {
    rwjabatanSIMASN,
    rwjabatanSAPK,
    rwjabatanModalAdd,

    // rwjabdef,
    // jdAdd,

    rwjabtam,
    jtAdd,

    rwjabjf,
    jfAdd,
  },

  computed: {
    dataSAPK: {
      get() {
        return getData.state.tabSAPK;
      },

      set(value) {
        getData.commit("getSAPK", value);
      },
    },

    headers() {
      const headers = [
        {
          text: "Jenis Jabatan / Eselon",
          align: "start",
          value: "jabatanjenis",
          width: "120px",
          sortable: false,
        },
        {
          text: "Nama Jabatan / Unit Organisasi",
          value: "jabatannama",
          sortable: false,
        },
        {
          text: "Pejabat Yang Menetapkan, Nomor dan Tanggal Surat Keputusan",
          value: "pejabat",
          sortable: false,
        },
        { text: "Pangkat", value: "golongan.nama", width: "100px" },
        { text: "TMT", value: "jabatan.tmt", width: "120px" },
        {
          text: "File",
          value: "dokumen",
          width: "100px",
          align: "center",
          sortable: false,
        },
      ];

      // if (this.session.authorities.some((item) => item === "CRUD_RWJAB")) {
      //   headers.push({
      //     text: "Actions",
      //     value: "actions",
      //     align: "center",
      //     width: "80px",
      //     sortable: false,
      //   });
      // }

      return headers;
    },
  },

  data: () => ({
    tab: null,
    tab_title: null,
    session: "",

    data: [],
  }),

  mounted() {
    this.session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
  },

  methods: {
    getSAPK() {
      getData.commit("getSAPK", true);
      getData.commit("getSIMASN", false);
    },

    getSIMASN() {
      getData.commit("getSIMASN", true);
      getData.commit("getSAPK", false);
    },
  },
};
</script>
