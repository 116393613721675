<template>
  <div v-if="session">
    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <div>
      <v-data-table
        :headers="headers"
        :items="rwjabatans"
        :mobile-breakpoint="0"
        :items-per-page="-1"
        :loading="ldata"
        hide-default-footer
      >
        <template v-slot:top>
          <rwjabatanModalEdit />
          <rwjabatanModalHapus />
        </template>

        <template v-slot:[`item.jabatanjenis`]="{ item }">
          {{ item.jabatan.jenis.nama }} <br />
          {{ item.eselon.nama }}
        </template>

        <template v-slot:[`item.jabatannama`]="{ item }">
          {{ item.namaJabatan }} <br />
          {{ item.namaUnor }}
        </template>

        <template v-slot:[`item.pejabat`]="{ item }">
          <div class="py-1">
            Pejabat : {{ item.suratKeputusan.pejabat }} <br />
            Nomor : {{ item.suratKeputusan.nomor }} <br />
            Tanggal : {{ item.suratKeputusan.tanggal }}
          </div>
        </template>

        <template v-slot:[`item.dokumen`]="{ item }">
          <v-row dense align="center" justify="center">
            <div v-if="item.document.repo_872 != null">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="download872(item.document)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="red"> mdi-file-pdf-box </v-icon>
                  </v-btn>
                </template>
                <span>SK JABATAN</span>
              </v-tooltip>
            </div>

            <div v-if="item.document.repo_873 != null">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="download873(item.document)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="red"> mdi-file-pdf-box </v-icon>
                  </v-btn>
                </template>
                <span>Surat Pelantikan</span>
              </v-tooltip>
            </div>
          </v-row>
        </template>

        <template
          v-slot:[`item.actions`]="{ item }"
          v-if="
            session.authorities.some((item) => item === 'CRUD_ADMINISTRATOR')
          "
        >
          <v-icon small class="mr-1" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="modalPDF" :max-width="dekstop">
      <v-card height="90vh" style="overflow: hidden">
        <v-toolbar dark color="primary" dense flat>
          <v-toolbar-title>{{ namaPDF }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closePDF()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card flat class="pa-2">
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="lbtn"
          ></v-progress-linear>

          <div v-else>
            <embed
              :src="defaultPDF"
              type="application/pdf"
              style="height: 80vh; width: 100%"
            />
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import rwjabatanModalEdit from "@/components/asn/profil/rwjabatan/ModalEdit";
import rwjabatanModalHapus from "@/components/asn/profil/rwjabatan/ModalHapus";

import modal_editState from "@/store/asn/rwjabatan/modal_edit";
import modal_hapusState from "@/store/asn/rwjabatan/modal_hapus";
import refreshView from "@/store/asn/rwjabatan/viewRwjabatan";
import setData from "@/store/asn/rwjabatan/tabs";

export default {
  name: "rwjabatan",

  components: {
    rwjabatanModalEdit,
    rwjabatanModalHapus,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },

      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },

    headers() {
      const headers = [
        {
          text: "Jenis Jabatan / Eselon",
          align: "start",
          value: "jabatanjenis",
          width: "120px",
          sortable: false,
        },
        {
          text: "Nama Jabatan / Unit Organisasi",
          value: "jabatannama",
          sortable: false,
        },
        {
          text: "Pejabat Yang Menetapkan, Nomor dan Tanggal Surat Keputusan",
          value: "pejabat",
          sortable: false,
        },
        { text: "Pangkat", value: "golongan.nama", width: "100px" },
        { text: "TMT", value: "jabatan.tmt", width: "120px" },
        {
          text: "File",
          value: "dokumen",
          width: "100px",
          align: "center",
          sortable: false,
        },
      ];

      if (
        this.session.authorities.some((item) => item === "CRUD_RWJAB")
      ) {
        headers.push({
          text: "Actions",
          value: "actions",
          align: "center",
          width: "80px",
          sortable: false,
        });
      }

      return headers;
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),

    dekstop: "75%",
    session: "",
    get_nip: "",

    ModalAdd: false,
    modalPDF: false,
    loading: false,
    lbtn: false,
    ldata: true,

    rwjabatans: [],
    rwjabatan: {},
    dataUtama: {},

    // ModalEdit: false,
    dialog: false,

    //Kebutuhan Edit
    editedIndex: -1,
    hapusIndex: -1,

    defaultPDF: "",
    namaPDF: "",
  }),

  async mounted() {
    this.session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  methods: {
    getData() {
      this.ldata = true;
      const url = process.env.VUE_APP_ASN + "jabatan/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.ldata = false;
          refreshView.commit("refreshData", false);
          this.rwjabatans = response.data.mapData.RwJabatan;
          setData.commit("getSAPKID", this.rwjabatans);
          this.dataUtama = response.data.mapData.DataUtama;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.rwjabatans.indexOf(item);
      this.rwjabatan = Object.assign({}, item);
      modal_editState.commit("toggleModal", true);
      modal_editState.commit("editModal", Object.assign({}, item));
      modal_editState.commit(
        "editDatautama",
        Object.assign({}, this.dataUtama)
      );
    },

    deleteItem(item) {
      this.hapusIndex = this.rwjabatans.indexOf(item);
      this.rwjabatan = Object.assign({}, item);
      modal_hapusState.commit("toggleModal", true);
      modal_hapusState.commit("hapusModal", Object.assign({}, item));
    },

    download872(value) {
      this.lbtn = true;
      this.modalPDF = true;
      this.namaPDF = "SK JABATAN";

      const url = process.env.VUE_APP_API + value.repo_872;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
          responseType: "blob",
        })
        .then((res) => {
          this.defaultPDF = URL.createObjectURL(res.data);
          this.lbtn = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    download873(value) {
      this.lbtn = true;
      this.modalPDF = true;
      this.namaPDF = "Surat Pelantikan";

      const url = process.env.VUE_APP_API + value.repo_873;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
          responseType: "blob",
        })
        .then((res) => {
          this.defaultPDF = URL.createObjectURL(res.data);
          this.lbtn = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    closePDF() {
      this.modalPDF = false;
    },
  },
};
</script>
