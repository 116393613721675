<template>
  <v-dialog
    v-model="ModalAdd"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn small fab text v-bind="attrs" v-on="{ ...tooltip, ...modal }">
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>Tambah Data</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="subtitle-1">
          Tambah Riwayat Jabatan JU/JF
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-6">
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Jenis</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-select
                  v-model="jenis_jf_id"
                  :items="refjenisjf"
                  item-text="nama"
                  item-value="id"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-n5" v-if="jenis_jf_id == 'pengangkatan'">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Jenjang</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-select
                  v-model="jenjang_jf"
                  :items="refjenjangjf"
                  item-text="nama"
                  item-value="id"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-n5" v-if="jenjang_jf == '3'">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Penjenjangan</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-select
                  v-model="penjenjangan"
                  :items="refpenjenjangan"
                  item-text="nama"
                  item-value="id"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Surat Keputusan (SK)</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  hint="Pejabat"
                  v-model="pejabat_sk"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  hint="Nomor"
                  v-model="nomor_sk"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="pick_tgl_sk"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tanggal_sk"
                      hint="Tanggal"
                      append-icon="mdi-calendar"
                      persistent-hint
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tanggal_sk"
                    @input="pick_tgl_sk = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Angka Kredit</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-text-field
                  v-model="angka_kredit"
                  dense
                  flat
                  outlined
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>TMT Jabatan</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-menu
                  v-model="pick_tmt_jabatan"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tmt_jabatan"
                      append-icon="mdi-calendar"
                      persistent-hint
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tmt_jabatan"
                    @input="pick_tmt_jabatan = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Golongan</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-autocomplete
                  v-model="golongan_id"
                  :items="refgol"
                  item-value="id"
                  outlined
                  dense
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.pangkat }} - {{ data.item.nama }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.pangkat }} - {{ data.item.nama }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <v-divider></v-divider>

          <!-- Upload File -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-5">
                <span>Upload</span>
              </v-col>
              <v-col cols="5">
                <v-file-input
                  class="mt-3"
                  outlined
                  dense
                  flat
                  prepend-icon
                  accept=".pdf"
                  label="Dokumen"
                  placeholder="Pilih File"
                  append-icon="mdi-paperclip"
                  @change="onUpload"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-col>

          <hr />

          <div class="mt-5 mr-5 ml-5">
            <v-btn
              color="primary"
              depressed
              :loading="lbtn"
              :disabled="lbtn"
              @click="add()"
            >
              SIMPAN
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import getRef from "@/helper/getReferensi.js";
import refreshView from "@/store/asn/rwjabjf/view";

export default {
  watch: {
    async ModalAdd(value) {
      if (value) {
        this.refgol = await getRef.Golongan(this.token);
        this.refjenjangjf = await getRef.ref_jenjangjf(this.token);
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),

    get_nip: "",

    ModalAdd: false,

    lbtn: false,
    lautocomplate: false,

    // Referensi
    refgol: [],
    refjenjangjf: [],
    refjenisjf: [
      {
        id: "pengangkatan",
        nama: "Pengangkatan dalam jabatan fungsional",
      },
      {
        id: "pemberhentian",
        nama: "Pemberhentian dalam jabatan fungsional",
      },
    ],
    refpenjenjangan: [
      {
        id: "muda",
        nama: "Muda",
      },
      {
        id: "madya",
        nama: "Madya",
      },
      {
        id: "utama",
        nama: "Utama",
      },
    ],

    // Date Picker
    pick_tmt_jabatan: false,
    pick_tgl_sk: false,

    // v-model
    jenis_jf_id: "",
    jenjang_jf: "",
    penjenjangan: "",
    golongan_id: "",
    angka_kredit: "",
    tmt_jabatan: "",
    pejabat_sk: "",
    nomor_sk: "",
    tanggal_sk: "",

    // UPLOAD FILE
    doc: "",
  }),

  async mounted() {
    this.token = await Cookie.get("token");
    this.get_nip = this.$route.params.id;
  },

  methods: {
    reset() {
      this.$refs.form.reset();
    },

    default() {
      this.jenis_jf_id = "";
      this.jenjang_jf = "";
      this.penjenjangan = "";
      this.golongan_id = "";
      this.angka_kredit = "";
      this.tmt_jabatan = "";
      this.pejabat_sk = "";
      this.nomor_sk = "";
      this.tanggal_sk = "";
      this.doc = "";
    },

    async add() {
      this.lbtn = true;

      const data = new FormData();
      data.append("doc", this.doc);
      data.append("jenis_jf_id", this.jenis_jf_id);
      data.append("jenjang_jf", this.jenjang_jf);
      data.append(
        "penjenjangan",
        this.jenjang_jf == "3" ? this.penjenjangan : null
      );
      data.append("golongan_id", this.golongan_id);
      data.append("angka_kredit", this.angka_kredit);
      data.append("tmt_jabatan", this.tmt_jabatan);
      data.append("pejabat_sk", this.pejabat_sk);
      data.append("nomor_sk", this.nomor_sk);
      data.append("tanggal_sk", this.tanggal_sk);

      const url = process.env.VUE_APP_ASN + "jabjf/nip/" + this.get_nip;

      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.lbtn = false;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.reset();
          this.ModalAdd = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.lbtn = false;
            this.reset();
            this.ModalAdd = false;
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.lbtn = false;
            this.reset();
            this.ModalAdd = false;
          }
        });
    },

    closeModal() {
      this.default();
      this.reset();
      this.ModalAdd = false;
    },

    onUpload(value) {
      this.doc = value;
    },
  },
};
</script>
