<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title class="subtitle-1"
          >Edit Riwayat jabatan / Pekerjaan</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-dialog v-model="dialog" persistent max-width="500">
        <v-card>
          <v-card-title class="headline error--text">
            PERINGATAN!
          </v-card-title>
          <v-card-text class="subtitle-1"
            >Jenis Jabatan Rangkap Tidak Bisa Dipilih.</v-card-text
          >
          <v-card-actions class="pb-5">
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="tutupDialog">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-5">
          <v-alert
            v-model="alertG"
            class="mb-10"
            type="error"
            dense
            dismissible
          >
            Terjadi kesalahan <strong>upload file!</strong>
          </v-alert>

          <!-- Jenis jabatan / Eselon -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Jenis jabatan / Eselon</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="editedItem.jabatan.jenis.id"
                  :items="refJenisjabatan"
                  item-text="nama"
                  item-value="id"
                  hint="Jenis jabatan"
                  persistent-hint
                  dense
                  outlined
                  @change="onChange"
                ></v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-menu
                  v-model="tmtjabatanPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.jabatan.tmt"
                      hint="TMT jabatan"
                      append-icon="mdi-calendar"
                      persistent-hint
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.jabatan.tmt"
                    @input="tmtjabatanPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2" v-if="viewTmtpelantikan">
                <v-menu
                  v-model="tmtPelantikanPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.jabatan.tmtPelantikan"
                      hint="TMT Pelantikan"
                      append-icon="mdi-calendar"
                      persistent-hint
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.jabatan.tmtPelantikan"
                    @input="tmtPelantikanPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2" v-if="viewEselon">
                <v-select
                  v-model="editedItem.eselon.id"
                  :items="refEselon"
                  item-text="nama"
                  item-value="id"
                  hint="Eselon"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <!-- Instansi Kerja -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Instansi Kerja</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-autocomplete
                  v-model="editedItem.satuanKerja.id"
                  :items="refInstansi"
                  item-text="nama"
                  item-value="id"
                  outlined
                  dense
                  return-object
                  @change="onSatuankerja"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <!-- Unit Organisasi -->
          <v-col cols="12" class="mt-n5" v-if="editedItem.jabatan.jenis.id">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Unit Organisasi</span>
              </v-col>
              <v-col cols="12" md="5" v-if="syncData">
                <v-dialog v-model="menuUnor" max-width="70%">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      flat
                      outlined
                      v-model="selected.name"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-form ref="pilihUnor">
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Pilih Unit Organisasi
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-divider class="pa-n5"></v-divider>
                      <v-list style="max-height: 200px" class="overflow-y-auto">
                        <div class="pl-5 pr-5 pt-3">
                          <div>
                            <v-autocomplete
                              :items="refUnor"
                              item-value="id"
                              item-text="nama"
                              outlined
                              dense
                              @change="unorChange"
                              return-object
                            >
                            </v-autocomplete>
                          </div>
                          <div>
                            <v-treeview
                              activatable
                              :items="refUnorsub"
                              item-text="namaUnor"
                              item-children="bawahan"
                              return-object
                              :active.sync="selection"
                              selection-type="independent"
                            ></v-treeview>
                          </div>
                        </div>
                      </v-list>
                      <div class="text-right pb-3">
                        <v-btn text @click="clearUnor()"> Clear </v-btn>
                        <v-btn color="error" text @click="menuUnor = false">
                          Batal
                        </v-btn>
                        <v-btn color="primary" text @click="menuUnor = false">
                          Pilih
                        </v-btn>
                      </div>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-col>

              <v-col cols="12" md="5" v-else>
                <v-text-field
                  v-model="editedItem.namaUnor"
                  hint="Nama Unor"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="5">
                <v-text-field
                  v-model="editedItem.namaJabatan"
                  hint="Nama Jabatan"
                  persistent-hint
                  dense
                  flat
                  outlined
                  :filled="syncData"
                  :disabled="syncData"
                  :readonly="syncData"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- jabatan Fungsional -->
          <v-col cols="12" class="mt-n5" v-if="viewFungsional && syncData">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>jabatan Fungsional</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-autocomplete
                  v-model="editedItem.jabatanFungsional.id"
                  :items="refjabatanfungsional"
                  item-text="nama"
                  item-value="id"
                  outlined
                  dense
                  return-object
                  @change="onChangeJF"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <!-- jabatan Fungsional Umum -->
          <v-col cols="12" class="mt-n5" v-if="viewFungsionalumum && syncData">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>jabatan Fungsional Umum</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-autocomplete
                  v-model="editedItem.jabatanFungsionalUmum.id"
                  :items="refpelaksana"
                  :loading="lautocomplate"
                  :search-input.sync="spelaksana"
                  dense
                  outlined
                  hide-no-data
                  hide-selected
                  return-object
                  item-value="id"
                  item-text="nama"
                  placeholder="Cari"
                  prepend-inner-icon="mdi-database-search"
                  @change="onChangeJFU"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <!-- Surat Keputusan -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Surat Keputusan (SK)</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  hint="Pejabat"
                  v-model="editedItem.suratKeputusan.pejabat"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  hint="Nomor"
                  v-model="editedItem.suratKeputusan.nomor"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="skTanggalPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.suratKeputusan.tanggal"
                      hint="Tanggal"
                      append-icon="mdi-calendar"
                      persistent-hint
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.suratKeputusan.tanggal"
                    @input="skTanggalPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Golongan -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Golongan</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-autocomplete
                  v-model="editedItem.golongan.id"
                  :items="refGolongan"
                  item-value="id"
                  outlined
                  dense
                >
                  <template slot="selection" slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ data.item.pangkat }} - {{ data.item.nama }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ data.item.pangkat }} - {{ data.item.nama }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <v-divider></v-divider>

          <!-- Upload File -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-5">
                <span>Upload</span>
              </v-col>
              <v-col cols="5" v-if="editedItem.document">
                <v-file-input
                  class="mt-3"
                  outlined
                  dense
                  flat
                  prepend-icon
                  accept=".pdf"
                  label="Dokumen SK Jabatan"
                  placeholder="Pilih File"
                  append-icon="mdi-paperclip"
                  @change="on872"
                >
                </v-file-input>
                <!-- <v-chip
                  class="mt-n10"
                  color="success"
                  small
                  @click="download872(editedItem.document.slug_872)"
                  v-if="editedItem.document.slug_872.dok_nama != null"
                >
                  Lihat File
                  {{ editedItem.document.slug_872.dok_nama }}
                </v-chip>
                <v-chip class="mt-n10" color="error" small v-else>
                  Belum Ada File
                </v-chip> -->
              </v-col>

              <v-col cols="5" v-if="editedItem.document">
                <v-file-input
                  class="mt-3"
                  outlined
                  dense
                  flat
                  prepend-icon
                  accept=".pdf"
                  label="Dokumen SK Surat Pelantikan"
                  placeholder="Pilih File"
                  append-icon="mdi-paperclip"
                  @change="on873"
                >
                </v-file-input>
                <!-- <v-chip
                  class="mt-n10"
                  color="success"
                  small
                  @click="download873(editedItem.document.slug_873)"
                  v-if="editedItem.document.slug_873.dok_nama != null"
                >
                  Lihat File
                  {{ editedItem.document.slug_873.dok_nama }}
                </v-chip>
                <v-chip class="mt-n10" color="error" small v-else>
                  Belum Ada File
                </v-chip> -->
              </v-col>
            </v-row>
          </v-col>
        </div>

        <hr />

        <v-dialog v-model="modalPDF" :max-width="dekstop">
          <v-card height="90vh" style="overflow: hidden">
            <v-toolbar dark color="primary" dense flat>
              <v-toolbar-title>{{ namaPDF }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closePDF()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card flat class="pa-2">
              <v-progress-linear
                indeterminate
                color="primary"
                v-if="lbtn"
              ></v-progress-linear>

              <div v-else>
                <embed
                  :src="defaultPDF"
                  type="application/pdf"
                  style="height: 80vh; width: 100%"
                />
              </div>

              <!-- <div v-else>
            <v-alert type="error"> Dokumen Tidak Ditemukan </v-alert>
          </div> -->
            </v-card>
          </v-card>
        </v-dialog>

        <div class="mt-5 mr-5 ml-5">
          <v-row>
            <v-col cols="12" class="d-flex flex-row-reverse">
              <span class="d-flex align-center error--text" v-if="syncData">
                *Integrasi Data Aktif : Data akan disinkron dengan data SAPK
              </span>
              <span class="d-flex align-center" v-else>
                *Integrasi Data Tidak Aktif : Data hanya di input di SIMASN
              </span>
            </v-col>
            <v-col cols="12" class="d-flex flex-row-reverse">
              <div>
                <v-btn
                  v-if="btnLoading"
                  color="primary"
                  depressed
                  @click="edit()"
                >
                  SIMPAN
                </v-btn>
                <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
              </div>
              <div class="mt-n3">
                <v-switch v-model="syncData" inset color="error"> </v-switch>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";
import getRef from "@/helper/getReferensi.js";

import modal_editState from "@/store/asn/rwjabatan/modal_edit";
import refreshView from "@/store/asn/rwjabatan/viewRwjabatan";

export default {
  name: "rwjabatanModalEdit",

  watch: {
    syncData() {
      this.onChange();
    },

    editedItem() {
      this.onChange();
    },

    selected() {
      if (this.editedItem.jabatan.jenis.id == 1) {
        this.editedItem.namaJabatan = this.selected.jabatan;
      }
    },

    async ModalEdit(value) {
      if (value) {
        this.refInstansi = await getRef.SatuanKerja(this.token);
        this.refJenisjabatan = await getRef.Jenisjabatan(this.token);
        this.refEselon = await getRef.Eselon(this.token);
        this.refGolongan = await getRef.Golongan(this.token);
        this.refUnor = await getRef.Unor(this.token);
        this.refjabatanfungsional = await getRef.Jabatanfungsional(this.token);
        this.refjabatanfungsionalumum = await getRef.Jabatanfungsionalumum(
          this.token
        );
      }
    },

    spelaksana(value) {
      let length = value ? value.length : 0;

      if (length > 5) {
        this.lautocomplate = true;
        const url = process.env.VUE_APP_ASN + "ref/pelaksana";

        let data = {
          cari: value,
        };

        this.http
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then(async (res) => {
            this.lautocomplate = false;
            this.refpelaksana =
              res.data.mapData.referensi.jabatanFungsionalUmum;
          })
          .catch((error) => {
            console.log(error.response.status);
            console.log("Error", error.message);
          });
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),

    get_nip: "",
    spelaksana: "",

    btnLoading: true,
    dialog: false,
    viewEselon: false,
    viewFungsional: false,
    viewFungsionalumum: false,
    viewTmtpelantikan: false,
    alertG: false,
    menuUnor: false,
    syncData: true,

    modalPDF: false,
    lbtn: false,
    lautocomplate: false,

    dekstop: "75%",
    defaultPDF: "",
    namaPDF: "",

    rwjabatan: {},
    selection: [],

    // Referensi
    refEselon: [],
    refJenisjabatan: [],
    refInstansi: [],
    refjabatanfungsional: [],
    refjabatanfungsionalumum: [],
    refUnor: [],
    refUnorsub: [],
    refGolongan: [],
    refpelaksana: [],

    // Date Picker
    tmtjabatanPicker: false,
    tmtPelantikanPicker: false,
    skTanggalPicker: false,

    // UPLOAD FILE
    file872: "",
    file873: "",
  }),

  async mounted() {
    this.token = await Cookie.get("token");
    this.get_nip = this.$route.params.id;
  },

  computed: {
    ModalEdit: {
      get() {
        return modal_editState.state.ModalEdit;
      },

      set(value) {
        modal_editState.commit("toggleModal", value);
      },
    },

    // filterFungsionalumum() {
    //   return this.refjabatanfungsionalumum.slice(0, 10)
    // },

    editedItem: {
      get() {
        return modal_editState.state.rwjabatan;
      },
    },

    editedDatautama: {
      get() {
        return modal_editState.state.dataUtama;
      },
    },

    selected() {
      if (!this.selection.length) {
        let kosong = {
          id: this.editedItem.unor.id,
          name: this.editedItem.namaUnor,
          jabatan: this.editedItem.namaJabatan,
        };
        return kosong;
      } else {
        let dataUnorSelect = {
          id: this.selection[0].id,
          name: this.selection[0].namaUnor,
          jabatan: this.selection[0].namaJabatan,
        };
        return dataUnorSelect;
      }
    },
  },

  methods: {
    reset() {
      this.syncData = false;
      this.selection = [];
      this.rwjabatan = {
        id: "",
        sapk: {
          id: "",
        },
        jabatan: {
          jenis: {
            id: "",
            nama: "",
          },
          tmt: "",
          tmtPelantikan: "",
        },
        satuanKerja: {
          id: "",
          nama: "",
          instansi: {
            id: "",
            nama: "",
            jenis: "",
          },
        },
        unor: {
          id: "",
          nama: "",
          atasan: {
            id: "",
            nama: "",
          },
          induk: {
            id: "",
            nama: "",
          },
        },
        eselon: {
          id: "",
          nama: "",
        },
        jabatanFungsional: {
          id: "",
          nama: "",
        },
        jabatanFungsionalUmum: {
          id: "",
          nama: "",
        },
        suratKeputusan: {
          pejabat: "",
          nomor: "",
          tanggal: "",
        },
        golongan: {
          id: "",
          nama: "",
          pangkat: "",
        },
        namaUnor: "",
        namaJabatan: "",
        NCSISTIME: "",
      };
    },

    async edit() {
      this.btnLoading = false;

      if (this.syncData) {
        // console.log('Ini DUPLEX')

        const data = new FormData();
        data.append("idJab", this.editedItem.id);
        data.append("SAPK_idJab", this.editedItem.sapk.id);
        data.append("doc", this.file872);
        data.append("doc", this.file873);
        data.append("jabatan_jenis_id", this.editedItem.jabatan.jenis.id);
        data.append("jabatan_tmt", this.editedItem.jabatan.tmt);
        data.append(
          "jabatan_tmtPelantikan",
          this.editedItem.jabatan.tmtPelantikan === null
            ? ""
            : this.editedItem.jabatan.tmtPelantikan
        );
        data.append("satuanKerja_id", this.editedItem.satuanKerja.id);
        data.append(
          "satuanKerja_instansi_id",
          this.editedItem.satuanKerja.instansi.id
        );
        data.append("unor_id", this.selected.id);
        data.append("eselon_id", this.editedItem.eselon.id);
        data.append(
          "jabatanFungsional_id",
          this.editedItem.jabatanFungsional.id
        );
        data.append(
          "jabatanFungsionalUmum_id",
          this.editedItem.jabatanFungsionalUmum.id
        );
        data.append(
          "suratKeputusan_pejabat",
          this.editedItem.suratKeputusan.pejabat
        );
        data.append(
          "suratKeputusan_nomor",
          this.editedItem.suratKeputusan.nomor
        );
        data.append(
          "suratKeputusan_tanggal",
          this.editedItem.suratKeputusan.tanggal
        );
        data.append("golongan_id", this.editedItem.golongan.id);
        data.append("namaUnor", this.selected.name);
        data.append("namaJabatan", this.editedItem.namaJabatan);
        data.append("siasn_token", this.token_siasn);
        data.append("wsbkn_token", this.token_wsbkn);

        const url =
          process.env.VUE_APP_ASN + "jabatan/sync/nip/" + this.get_nip;

        this.http
          .put(url, data, {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then((response) => {
            this.btnLoading = true;
            if (response.data.success) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("berhasilAlert", true);
              refreshView.commit("gagalAlert", false);
              refreshView.commit("success", response.data.success);
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", response.data.success);
            }
            this.ModalEdit = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              Auth.logout(this.token);
            } else if (error.response) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              this.btnLoading = true;
              this.ModalEdit = false;
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              console.log("Error", error.message);
              this.btnLoading = true;
              this.ModalEdit = false;
            }
          });
      } else {
        // console.log('Ini SIMASN')

        const data = new FormData();
        data.append("idJab", this.editedItem.id);
        data.append("doc", this.file872);
        data.append("doc", this.file873);
        data.append("jabatan_jenis_id", this.editedItem.jabatan.jenis.id);
        data.append("jabatan_tmt", this.editedItem.jabatan.tmt);
        data.append(
          "jabatan_tmtPelantikan",
          this.editedItem.jabatan.tmtPelantikan === null
            ? ""
            : this.editedItem.jabatan.tmtPelantikan
        );
        data.append(
          "satuanKerja_instansi_id",
          this.editedItem.satuanKerja.instansi.id
        );
        data.append("eselon_id", this.editedItem.eselon.id);
        data.append(
          "jabatanFungsional_id",
          this.editedItem.jabatanFungsional.id
        );
        data.append(
          "jabatanFungsionalUmum_id",
          this.editedItem.jabatanFungsionalUmum.id
        );
        data.append(
          "suratKeputusan_pejabat",
          this.editedItem.suratKeputusan.pejabat
        );
        data.append(
          "suratKeputusan_nomor",
          this.editedItem.suratKeputusan.nomor
        );
        data.append(
          "suratKeputusan_tanggal",
          this.editedItem.suratKeputusan.tanggal
        );
        data.append("golongan_id", this.editedItem.golongan.id);
        data.append("namaUnor", this.selected.name);
        data.append("namaJabatan", this.editedItem.namaJabatan);

        const url = process.env.VUE_APP_ASN + "jabatan/nip/" + this.get_nip;

        this.http
          .put(url, data, {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then((response) => {
            this.btnLoading = true;
            if (response.data.success) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("berhasilAlert", true);
              refreshView.commit("gagalAlert", false);
              refreshView.commit("success", response.data.success);
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", response.data.success);
            }
            this.closeModal();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              Auth.logout(this.token);
            } else if (error.response) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              this.btnLoading = true;
              this.closeModal();
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              console.log("Error", error.message);
              this.btnLoading = true;
              this.closeModal();
            }
          });
      }
    },

    onChange(value) {
      if (value == 1 || this.editedItem.jabatan.jenis.id == 1) {
        this.viewEselon = true;
        this.viewFungsional = false;
        this.viewFungsionalumum = false;
        this.viewTmtpelantikan = true;
      } else if (value == 2 || this.editedItem.jabatan.jenis.id == 2) {
        this.viewEselon = false;
        this.viewFungsional = true;
        this.viewFungsionalumum = false;
        this.viewTmtpelantikan = true;
        this.tmtPelantikan = "";
      } else if (value == 4 || this.editedItem.jabatan.jenis.id == 4) {
        this.viewEselon = false;
        this.viewFungsional = false;
        this.viewFungsionalumum = true;
        this.viewTmtpelantikan = false;
        this.tmtPelantikan = "";
      } else if (value == 3 || this.editedItem.jabatan.jenis.id == 3) {
        this.dialog = true;
      }
    },

    onChangeJF(value) {
      this.editedItem.namaJabatan = value.nama;
      this.editedItem.jabatanFungsional.id = value.id;
    },

    onChangeJFU(value) {
      this.editedItem.namaJabatan = value.nama;
      this.editedItem.jabatanFungsionalUmum.id = value.id;
    },

    clearUnor() {
      this.$refs.pilihUnor.reset();
    },

    async closeModal() {
      await this.reset();
      await modal_editState.commit(
        "editModal",
        Object.assign({}, this.rwjabatan)
      );
      await refreshView.commit("refreshData", true);
      this.ModalEdit = false;
    },

    async unorChange(value) {
      const token = await Cookie.get("token");
      const response = await getRef.subUnor(token, value.id);
      this.refUnorsub = response;
    },

    onSatuankerja(value) {
      this.editedItem.satuanKerja.id = value.id;
      this.editedItem.satuanKerja.instansi.id = value.instansi.id;
    },

    on872(value) {
      this.file872 = value;
    },

    on873(value) {
      this.file873 = value;
    },

    download872(value) {
      this.lbtn = true;
      this.modalPDF = true;
      this.namaPDF = value.dok_nama;
      let baseurl =
        "https://apimws.bkn.go.id:8243/apisiasn/1.0/download-dok?filePath=";

      const url = baseurl + value.dok_uri;

      this.http
        .get(url, {
          headers: {
            Auth: "bearer " + this.token_siasn,
            Authorization: "Bearer " + this.token_wsbkn,
          },
          responseType: "blob",
        })
        .then((res) => {
          this.defaultPDF = URL.createObjectURL(res.data);
          this.lbtn = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    download873(value) {
      this.lbtn = true;
      this.modalPDF = true;
      this.namaPDF = value.dok_nama;
      let baseurl =
        "https://apimws.bkn.go.id:8243/apisiasn/1.0/download-dok?filePath=";

      const url = baseurl + value.dok_uri;

      this.http
        .get(url, {
          headers: {
            Auth: "bearer " + this.token_siasn,
            Authorization: "Bearer " + this.token_wsbkn,
          },
          responseType: "blob",
        })
        .then((res) => {
          this.defaultPDF = URL.createObjectURL(res.data);
          this.lbtn = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    tutupDialog() {
      this.editedItem.jabatan.jenis.id = "";
      this.dialog = false;
    },

    closePDF() {
      this.modalPDF = false;
    },
  },
};
</script>
