<template>
  <v-dialog v-model="ModalToSIMASN" persistent max-width="40%">
    <v-card>
      <v-card-title class="headline"> Sinkron Data Ke IDASN? </v-card-title>
      <v-card-text>
        Data penghargaan yang anda pilih akan disalin ke data penghargaan di
        IDASN!
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" small depressed @click="ModalToSIMASN = false">
          Batal
        </v-btn>
        <v-btn
          color="primary"
          small
          depressed
          :loading="lbtn"
          :disabled="lbtn"
          @click="toSIMASN()"
        >
          Ya
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";
import refreshView from "@/store/asn/rwjabatan/viewRwjabatan";

import modal_tosimasnState from "@/store/asn/rwjabatan/modal_to_simasn";

export default {
  data: () => ({
    token: Cookie.get("token"),

    lbtn: false,
    get_nip: "",

    // UPLOAD FILE
    doc1: null,
    doc2: null,

    file872: null,
    file873: null,
  }),

  computed: {
    ModalToSIMASN: {
      get() {
        return modal_tosimasnState.state.ModalToSIMASN;
      },

      set(value) {
        modal_tosimasnState.commit("toggleModal", value);
      },
    },

    item: {
      get() {
        return modal_tosimasnState.state.rwjabatan;
      },
    },

    editedDatautama: {
      get() {
        return modal_tosimasnState.state.dataUtama;
      },
    },
  },

  methods: {
    async toSIMASN() {
      this.lbtn = true;

      const url =
        process.env.VUE_APP_ASN + "jabatan/nip/" + this.$route.params.id;

      const data = new FormData();
      data.append("doc", this.file872);
      data.append("doc", this.file873);
      data.append("jabatan_jenis_id", this.item.jabatan.jenis.id);
      data.append("jabatan_tmt", this.item.jabatan.tmt);
      data.append("jabatan_tmtPelantikan", this.item.jabatan.tmtPelantikan);
      data.append("satuanKerja_id", this.item.satuanKerja.id);
      data.append("satuanKerja_instansi_id", this.item.satuanKerja.instansi.id);
      data.append("unor_id", this.item.unor.id);
      data.append("eselon_id", this.item.eselon.id);
      data.append("jabatanFungsional_id", this.item.jabatanFungsional.id);
      data.append(
        "jabatanFungsionalUmum_id",
        this.item.jabatanFungsionalUmum.id
      );
      data.append("suratKeputusan_pejabat", this.item.suratKeputusan.pejabat);
      data.append("suratKeputusan_nomor", this.item.suratKeputusan.nomor);
      data.append("suratKeputusan_tanggal", this.item.suratKeputusan.tanggal);
      data.append("golongan_id", this.item.golongan.id);
      data.append("namaUnor", this.item.namaUnor);
      data.append("namaJabatan", this.item.namaJabatan);

      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.lbtn = false;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            this.lbtn = false;
            console.log(error.response.status);
          } else {
            this.lbtn = false;
            console.log("Error", error.message);
          }
        });
    },

    on872(value) {
      this.file872 = value;
    },

    on873(value) {
      this.file873 = value;
    },

    closeModal() {
      this.file872 = null;
      this.file873 = null;

      this.doc1 = null;
      this.doc2 = null;
      this.ModalToSIMASN = false;
    },
  },
};
</script>
