<template>
  <div v-if="session">
    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <div>
      <v-data-table
        :headers="headers"
        :items="data"
        :mobile-breakpoint="0"
        :items-per-page="-1"
        :loading="ldata"
        hide-default-footer
      >
        <template v-slot:top>
          <ModalEdit />
          <ModalHapus />
        </template>

        <template v-slot:[`item.jenis`]="{ item }">
          <span v-if="item.jenis_jf_id == 'pengangkatan'">
            Pengangkatan dalam jabatan fungsional
          </span>
          <span v-else> Pemberhentian dalam jabatan fungsional </span>
        </template>

        <template v-slot:[`item.jabatannama`]="{ item }">
          {{ item.nama_jabatan }} <br />
        </template>

        <template v-slot:[`item.pejabat`]="{ item }">
          <div class="py-1">
            Pejabat : {{ item.pejabat_sk }} <br />
            Nomor : {{ item.nomor_sk }} <br />
            Tanggal : {{ item.tgl_sk }}
          </div>
        </template>

        <template v-slot:[`item.tmt`]="{ item }">
          <div class="py-1">{{ item.tmt_jabatan }}</div>
        </template>

        <template v-slot:[`item.dokumen`]="{ item }">
          <v-row dense align="center" justify="center">
            <div v-if="item.doc != null">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="download(item.id)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="red"> mdi-file-pdf-box </v-icon>
                  </v-btn>
                </template>
                <span>Dokumen</span>
              </v-tooltip>
            </div>
          </v-row>
        </template>

        <template
          v-slot:[`item.actions`]="{ item }"
          v-if="session.authorities.some((item) => item === 'CRUD_JABJF')"
        >
          <v-icon small class="mr-1" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="modalPDF" :max-width="dekstop">
      <v-card height="90vh" style="overflow: hidden">
        <v-toolbar dark color="primary" dense flat>
          <v-toolbar-title>{{ namaPDF }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closePDF()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card flat class="pa-2">
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="lbtn"
          ></v-progress-linear>

          <div v-else>
            <embed
              :src="defaultPDF"
              type="application/pdf"
              style="height: 80vh; width: 100%"
            />
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import ModalEdit from "@/components/asn/profil/rwjabjf/ModalEdit";
import ModalHapus from "@/components/asn/profil/rwjabjf/ModalHapus";

import modal_editState from "@/store/asn/rwjabjf/modal_edit";
import modal_hapusState from "@/store/asn/rwjabjf/modal_hapus";
import refreshView from "@/store/asn/rwjabjf/view";

export default {
  name: "rwjabjf",

  components: {
    ModalEdit,
    ModalHapus,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },

      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },

    headers() {
      const headers = [
        {
          text: "Jenis",
          align: "start",
          value: "jenis",
          sortable: false,
        },
        {
          text: "Jenjang",
          value: "jenjang_jf.nama",
          sortable: false,
        },
        {
          text: "Pejabat Yang Menetapkan",
          value: "pejabat",
          sortable: false,
        },
        { text: "Angka Kredit", value: "angka_kredit" },
        { text: "TMT", value: "tmt" },
        {
          text: "File",
          value: "dokumen",
          width: "100px",
          align: "center",
          sortable: false,
        },
      ];

      if (this.session.authorities.some((item) => item === "CRUD_JABJF")) {
        headers.push({
          text: "Actions",
          value: "actions",
          align: "center",
          width: "80px",
          sortable: false,
        });
      }

      return headers;
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),

    dekstop: "75%",
    session: "",
    get_nip: "",
    cek: 0,

    modalPDF: false,
    defaultPDF: "",
    namaPDF: "",
    loading: false,
    lbtn: false,
    ldata: true,

    data: [],
  }),

  async mounted() {
    this.session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  methods: {
    getData() {
      this.ldata = true;
      const url = process.env.VUE_APP_ASN + "jabjf/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.ldata = false;
          refreshView.commit("refreshData", false);
          this.cek = res.data.mapData.data.length;
          this.data = res.data.mapData.data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    editItem(item) {
      modal_editState.commit("toggleModal", true);
      modal_editState.commit("editModal", item);
    },

    deleteItem(item) {
      modal_hapusState.commit("toggleModal", true);
      modal_hapusState.commit("hapusModal", item);
    },

    download(value) {
      this.lbtn = true;
      this.modalPDF = true;
      this.namaPDF = "Dokumen";

      const url =
        process.env.VUE_APP_API +
        "/idasn/jabjf/doc/" +
        this.get_nip +
        "/" +
        value +
        "/doc";

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
          responseType: "blob",
        })
        .then((res) => {
          this.defaultPDF = URL.createObjectURL(res.data);
          this.lbtn = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    closePDF() {
      this.modalPDF = false;
    },
  },
};
</script>
