<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="rwjabatan_SAPK"
      :mobile-breakpoint="0"
      :items-per-page="-1"
      :loading="loading"
      hide-default-footer
      style="text-overflow: ellipsis; white-space: nowrap"
    >
      <template v-slot:top>
        <v-alert v-model="alertBerhasil" type="success" dense dismissible>
          {{ alertMassage }}
        </v-alert>
        <v-alert v-model="alertGagal" type="error" dense dismissible>
          {{ alertMassage }}
        </v-alert>
        <rwjabatanModalToSIMASN />
      </template>
      <template v-slot:[`item.jabatanjenis`]="{ item }">
        {{ item.jabatan.jenis.nama }} <br />
        {{ item.eselon.nama }}
      </template>
      <template v-slot:[`item.jabatannama`]="{ item }">
        {{ item.namaJabatan }} <br />
        {{ item.namaUnor }}
      </template>
      <template v-slot:[`item.pejabat`]="{ item }">
        Nomor : {{ item.suratKeputusan.nomor }} <br />
        Tanggal : {{ item.suratKeputusan.tanggal }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="toSIMASN(item)" v-bind="attrs" v-on="on">
              <v-icon color="primary"> mdi-database-arrow-left </v-icon>
            </v-btn>
          </template>
          <span>Salin Data</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import apiURL from "@/helper/getURL.js";
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import refreshView from "@/store/asn/rwjabatan/tabs";
import refreshData from "@/store/asn/rwjabatan/viewRwjabatan";
import modal_tosimasnState from "@/store/asn/rwjabatan/modal_to_simasn";

import rwjabatanModalToSIMASN from "@/components/asn/profil/rwjabatan/ModalToSIMASN";

export default {
  name: "rwjabatanModalSAPK",

  components: {
    rwjabatanModalToSIMASN,
  },

  computed: {
    tabSAPK: {
      get() {
        return refreshView.state.tabSAPK;
      },
    },

    sapkID: {
      get() {
        return refreshView.state.sapkID;
      },
    },

    refresh: {
      get() {
        return refreshData.state.RefreshSAPK;
      },
    },

    alertMassage: {
      get() {
        return refreshData.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshData.state.success;
      },

      set(value) {
        refreshData.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshData.state.alertBerhasil;
      },

      set(value) {
        refreshData.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshData.state.alertGagal;
      },

      set(value) {
        refreshData.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    tabSAPK(newVal) {
      if (newVal) {
        this.getDataSAPK();
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    Modal_rwjabatan_SAPK: false,
    loading: false,
    rwjabatan_SAPK: [],
    get_nip: "",

    headers: [
      {
        text: "Jenis Jabatan / Eselon",
        align: "start",
        value: "jabatanjenis",
        width: "200px",
      },
      {
        text: "Nama Jabatan / Unit Organisasi",
        value: "jabatannama",
        width: "400px",
      },
      {
        text: "Pejabat yang Menetapkan, Nomor dan Tanggal Surat Keputusan",
        value: "pejabat",
        width: "240px",
      },
      { text: "TMT", value: "jabatan.tmt" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        width: "80px",
      },
    ],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
    if (this.tabSAPK) {
      this.getDataSAPK();
    }
  },

  methods: {
    getDataSAPK() {
      this.loading = true;
      const token = Cookie.get("token");
      const url = apiURL.baseURL + "jabatan/nip/" + this.get_nip + "/bkn";

      this.http
        .get(
          url,
          { headers: apiURL.Config(token).headers },
          apiURL.Config(token).data
        )
        .then((response) => {
          this.rwjabatan_SAPK = response.data.mapData.RwJabatan;
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    cek(value) {
      if (!this.sapkID.find((d) => d.sapk.id === value)) {
        return false;
      } else {
        return true;
      }
    },

    toSIMASN(item) {
      modal_tosimasnState.commit("toggleModal", true);
      modal_tosimasnState.commit("tosimasnModal", item);
      modal_tosimasnState.commit("Datautama", this.dataUtama);
    },
  },
};
</script>
