import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
    editedIndex: -1,

    dataUtama: {
      id: '',
      SAPK: {
        id: ''
      },
      nipBaru: '',
      nipLama: ''
    },

    data: {
      id: '',
      jenis_penugasan: {
        id: '',
        nama: ''
      },
      golongan: {
        id: '',
        nama: ''
      },
      nama_jabatan: '',
      tmt_jabatan: null,
      period_mulai: null,
      period_akhir: null,
      pejabat_sk: '',
      nomor_sk: '',
      tgl_sk: null,
      status_jabtam: '',
      doc: null,
      userId: '',
      NCSISTIME: ''
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },

    editModal(state, value) {
      state.data = value
    },

    editDatautama(state, value) {
      state.dataUtama = value
    },

    indexItem(state, value) {
      state.editedIndex = value
    }
  }
})