<template>
  <v-dialog
    v-model="ModalAdd"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn small fab text v-bind="attrs" v-on="{ ...tooltip, ...modal }">
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>Tambah Riwayat</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="subtitle-1"
          >Tambah Riwayat Jabatan / Pekerjaan</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-dialog v-model="dialog" persistent max-width="500">
        <v-card>
          <v-card-title class="headline error--text">
            PERINGATAN!
          </v-card-title>
          <v-card-text class="subtitle-1"
            >Jenis Jabatan Rangkap Tidak Bisa Dipilih.</v-card-text
          >
          <v-card-actions class="pb-5">
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="tutupDialog">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-6">
          <v-alert
            v-model="alertG"
            class="mb-10"
            type="error"
            dense
            dismissible
          >
            Terjadi kesalahan <strong>upload file!</strong>
          </v-alert>

          <!-- Jenis Jabatan / Eselon -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Jenis Jabatan / Eselon</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="jenisJabatan"
                  :items="refJenisjabatan"
                  item-text="nama"
                  item-value="id"
                  hint="Jenis Jabatan"
                  persistent-hint
                  dense
                  outlined
                  @change="onChange"
                ></v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-menu
                  v-model="tmtJabatanPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tmtJabatan"
                      hint="TMT Jabatan"
                      append-icon="mdi-calendar"
                      persistent-hint
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tmtJabatan"
                    @input="tmtJabatanPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2" v-if="viewTmtpelantikan">
                <v-menu
                  v-model="tmtPelantikanPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tmtPelantikan"
                      hint="TMT Pelantikan"
                      append-icon="mdi-calendar"
                      persistent-hint
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tmtPelantikan"
                    @input="tmtPelantikanPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2" v-if="viewEselon">
                <v-select
                  v-model="eselon"
                  :items="refEselon"
                  item-text="nama"
                  item-value="id"
                  hint="Eselon"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <!-- Instansi Kerja -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Instansi Kerja</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-autocomplete
                  v-model="satuanKerja"
                  :items="refInstansi"
                  item-value="id"
                  item-text="nama"
                  outlined
                  dense
                  return-object
                  @change="onSatuankerja"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <!-- Unit Organisasi -->
          <v-col cols="12" class="mt-n5" v-if="jenisJabatan">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Unit Organisasi</span>
              </v-col>
              <v-col cols="12" md="5" v-if="syncData">
                <v-dialog v-model="menuUnor" max-width="70%">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      flat
                      outlined
                      v-model="selected.name"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      hint="Nama Unor"
                      persistent-hint
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-form ref="pilihUnor">
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              >Pilih Unit Organisasi</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-divider class="pa-n5"></v-divider>
                      <v-list style="max-height: 300px" class="overflow-y-auto">
                        <div class="pl-5 pr-5 pt-3">
                          <div>
                            <v-autocomplete
                              :items="refUnor"
                              item-value="id"
                              item-text="nama"
                              outlined
                              dense
                              @change="unorChange"
                              return-object
                            >
                            </v-autocomplete>
                          </div>
                          <div>
                            <v-treeview
                              activatable
                              :items="refUnorsub"
                              item-text="namaUnor"
                              item-children="bawahan"
                              return-object
                              :active.sync="selection"
                              selection-type="independent"
                            ></v-treeview>
                          </div>
                        </div>
                      </v-list>
                      <div class="text-right pb-3">
                        <v-btn text @click="clearUnor()"> Clear </v-btn>
                        <v-btn color="error" text @click="menuUnor = false">
                          Batal
                        </v-btn>
                        <v-btn color="primary" text @click="menuUnor = false">
                          Pilih
                        </v-btn>
                      </div>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-col>

              <v-col cols="12" md="5" v-else>
                <v-text-field
                  v-model="namaUnor"
                  hint="Nama Unor"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="5">
                <v-text-field
                  v-model="namaJabatan"
                  hint="Nama Jabatan"
                  persistent-hint
                  dense
                  flat
                  outlined
                  :filled="syncData"
                  :disabled="syncData"
                  :readonly="syncData"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Jabatan Fungsional -->
          <v-col cols="12" class="mt-n5" v-if="viewFungsional && syncData">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Jabatan Fungsional</span>
              </v-col>
              <v-col cols="12" md="10">
                <v-autocomplete
                  v-model="jabatanFungsional"
                  :items="refJabatanfungsional"
                  item-text="nama"
                  item-value="id"
                  outlined
                  dense
                  return-object
                  @change="onChangeJF"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <!-- Jabatan Fungsional Umum -->
          <v-col cols="12" class="mt-n5" v-if="viewFungsionalumum && syncData">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Jabatan Fungsional Umum</span>
              </v-col>

              <v-col cols="12" md="10">
                <v-autocomplete
                  v-model="jabatanFungsionalUmum"
                  :items="refpelaksana"
                  :loading="lautocomplate"
                  :search-input.sync="spelaksana"
                  dense
                  outlined
                  hide-no-data
                  hide-selected
                  return-object
                  item-value="id"
                  item-text="nama"
                  placeholder="Cari"
                  prepend-inner-icon="mdi-database-search"
                  @change="onChangeJFU"
                ></v-autocomplete>
              </v-col>

              <!-- <v-col cols="12" md="2">
                <v-autocomplete
                  v-model="jabatanFungsionalUmum"
                  :items="itemskodeCepat"
                  :loading="isLoadingkodeCepat"
                  :search-input.sync="searchkodeCepat"
                  item-text="kodeCepat"
                  item-value="id"
                  hint="Kode Cepat"
                  persistent-hint
                  outlined
                  dense
                  return-object
                  @change="onChangeJFU"
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.kodeCepat }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.kodeCepat }} - {{ data.item.nama }}
                  </template>
                </v-autocomplete>
              </v-col>
              
              <v-col cols="12" md="8">
                <v-autocomplete
                  v-model="jabatanFungsionalUmum"
                  :items="itemsJFU"
                  :loading="isLoadingJFU"
                  :search-input.sync="searchJFU"
                  item-text="nama"
                  item-value="id"
                  outlined
                  dense
                  return-object
                  @change="onChangeJFU"
                ></v-autocomplete>
              </v-col> -->
            </v-row>
          </v-col>

          <!-- Surat Keputusan -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Surat Keputusan (SK)</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  hint="Pejabat"
                  v-model="skPejabat"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  hint="Nomor"
                  v-model="skNomor"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="skTanggalPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="skTanggal"
                      hint="Tanggal"
                      append-icon="mdi-calendar"
                      persistent-hint
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="skTanggal"
                    @input="skTanggalPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Golongan -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Golongan</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-autocomplete
                  v-model="golongan"
                  :items="refGolongan"
                  item-value="id"
                  outlined
                  dense
                >
                  <template slot="selection" slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ data.item.pangkat }} - {{ data.item.nama }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ data.item.pangkat }} - {{ data.item.nama }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <v-divider></v-divider>

          <!-- Upload File -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-5">
                <span>Upload</span>
              </v-col>
              <v-col cols="5">
                <v-file-input
                  class="mt-3"
                  outlined
                  dense
                  flat
                  prepend-icon
                  accept=".pdf"
                  label="Dokumen SK Jabatan"
                  placeholder="Pilih File"
                  append-icon="mdi-paperclip"
                  @change="on872"
                >
                </v-file-input>
              </v-col>

              <v-col cols="5">
                <v-file-input
                  class="mt-3"
                  outlined
                  dense
                  flat
                  prepend-icon
                  accept=".pdf"
                  label="Dokumen SK Surat Pelantikan"
                  placeholder="Pilih File"
                  append-icon="mdi-paperclip"
                  @change="on873"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-col>

          <hr />

          <div class="mt-5 mr-5 ml-5">
            <v-row>
              <v-col cols="12" class="d-flex flex-row-reverse">
                <span class="d-flex align-center error--text" v-if="syncData">
                  <b>*Integrasi Data Aktif : Data akan di input ke-BKN</b>
                </span>
                <span class="d-flex align-center" v-else>
                  <b>*Integrasi Data Tidak Aktif : Data hanya di input di IDASN</b>
                </span>
              </v-col>
              <v-col cols="12" class="d-flex flex-row-reverse">
                <div>
                  <v-btn
                    v-if="btnLoading"
                    color="primary"
                    depressed
                    @click="add()"
                  >
                    SIMPAN
                  </v-btn>
                  <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
                </div>
                <div class="mt-n3">
                  <v-switch v-model="syncData" inset color="error"> </v-switch>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import getRef from "@/helper/getReferensi.js";
import refreshView from "@/store/asn/rwjabatan/viewRwjabatan";

export default {
  name: "rwjabatanModalAdd",

  computed: {
    selected() {
      if (!this.selection.length) {
        return "kosong";
      } else {
        let dataUnorSelect = {
          id: this.selection[0].id,
          name: this.selection[0].namaUnor,
          jabatan: this.selection[0].namaJabatan,
        };
        return dataUnorSelect;
      }
    },

    itemskodeCepat() {
      return this.entrieskodeCepat.map((entry) => {
        const Description =
          entry.kodeCepat.length > this.descriptionLimit
            ? entry.slice(0, this.descriptionLimit) + "..."
            : entry.Description;
        return Object.assign({}, entry, { Description });
      });
    },

    itemsJFU() {
      return this.entriesJFU.map((entry) => {
        const Description =
          entry.length > this.descriptionLimit
            ? entry.slice(0, this.descriptionLimit) + "..."
            : entry.Description;
        return Object.assign({}, entry, { Description });
      });
    },
  },

  watch: {
    async ModalAdd(value) {
      if (value) {
        this.refInstansi = await getRef.SatuanKerja(this.token);
        this.refJenisjabatan = await getRef.Jenisjabatan(this.token);
        this.refJabatanfungsional = await getRef.Jabatanfungsional(this.token);
        this.refUnor = await getRef.Unor(this.token);
        this.refEselon = await getRef.Eselon(this.token);
        this.refGolongan = await getRef.Golongan(this.token);
        // this.refJabatanfungsionalumum = await getRef.Jabatanfungsionalumum(this.token)
      }
    },

    selected() {
      if (this.jenisJabatan == 1) {
        this.namaJabatan = this.selected.jabatan;
      }
    },

    searchkodeCepat() {
      // Items have already been loaded
      if (this.itemskodeCepat.length > 0) return;

      // Items have already been requested
      if (this.isLoadingkodeCepat) return;
      this.isLoadingkodeCepat = true;

      // Lazily load input items
      const url = process.env.VUE_APP_ASN + "ref/jfu";

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.entrieskodeCepat =
            response.data.mapData.referensi.jabatanFungsionalUmum;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoadingkodeCepat = false));
    },

    searchJFU() {
      // Items have already been loaded
      if (this.itemsJFU.length > 0) return;

      // Items have already been requested
      if (this.isLoadingJFU) return;
      this.isLoadingJFU = true;

      // Lazily load input items
      const url = process.env.VUE_APP_ASN + "ref/jfu";

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.entriesJFU =
            response.data.mapData.referensi.jabatanFungsionalUmum;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoadingJFU = false));
    },

    spelaksana(value) {
      let length = value ? value.length : 0;

      if (length > 5) {
        this.lautocomplate = true;
        const url = process.env.VUE_APP_ASN + "ref/pelaksana";

        let data = {
          cari: value,
        };

        this.http
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then(async (res) => {
            this.lautocomplate = false;
            this.refpelaksana =
              res.data.mapData.referensi.jabatanFungsionalUmum;
          })
          .catch((error) => {
            console.log(error.response.status);
            console.log("Error", error.message);
          });
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),

    get_nip: "",
    spelaksana: "",

    ModalAdd: false,
    dialog: false,
    alertG: false,
    btnLoading: true,
    lautocomplate: false,

    viewEselon: false,
    viewFungsional: false,
    viewFungsionalumum: false,
    viewTmtpelantikan: false,
    menuUnor: false,
    syncData: true,
    selection: [],
    descriptionLimit: 20,

    entrieskodeCepat: [],
    isLoadingkodeCepat: false,
    searchkodeCepat: null,

    entriesJFU: [],
    isLoadingJFU: false,
    searchJFU: null,

    // Referensi
    refEselon: [],
    refJenisjabatan: [],
    refInstansi: [],
    refJabatanfungsional: [],

    // refJabatanfungsionalumum: [],
    refUnor: [],
    refUnorsub: [],
    refGolongan: [],
    refpelaksana: [],

    // Date Picker
    tmtJabatanPicker: false,
    tmtPelantikanPicker: false,
    skTanggalPicker: false,

    tmtJabatan: new Date().toISOString().substr(0, 10),
    tmtPelantikan: "",
    skTanggal: new Date().toISOString().substr(0, 10),

    // v-model
    jenisJabatan: "",
    eselon: "",
    satuanKerja: "A5EB03E24288F6A0E040640A040252AD",
    satuankerja_id: "A5EB03E24288F6A0E040640A040252AD",
    instansikerja_id: "A5EB03E23C74F6A0E040640A040252AD",
    jabatanFungsional: "",
    jabatanFungsionalUmum: "",
    golongan: "",
    Unor: "",
    skPejabat: "",
    skNomor: "",
    namaUnor: "",
    namaJabatan: "",

    // UPLOAD FILE
    file872: "",
    file873: "",
  }),

  async mounted() {
    this.token = await Cookie.get("token");
    this.get_nip = this.$route.params.id;
  },

  methods: {
    reset() {
      this.$refs.form.reset();
    },

    default() {
      this.viewEselon = false;
      this.viewFungsional = false;
      this.viewFungsionalumum = false;
      this.viewTmtpelantikan = false;
      this.jenisJabatan = "";
      this.eselon = "";
      this.satuanKerja = "A5EB03E24288F6A0E040640A040252AD";
      this.satuankerja_id = "A5EB03E24288F6A0E040640A040252AD";
      this.instansikerja_id = "A5EB03E23C74F6A0E040640A040252AD";
      this.jabatanFungsional = "";
      this.jabatanFungsionalUmum = "";
      this.Golongan = "";
      this.Unor = "";
      this.skPejabat = "";
      this.skNomor = "";
      this.tmtPelantikan = "";
    },

    async add() {
      this.btnLoading = false;

      if (this.syncData) {
        // console.log('Ini DUPLEX')

        const data = new FormData();
        data.append("doc", this.file872);
        data.append("doc", this.file873);
        data.append("jabatan_jenis_id", this.jenisJabatan);
        data.append("jabatan_tmt", this.tmtJabatan);
        data.append("jabatan_tmtPelantikan", this.tmtPelantikan);
        data.append("satuanKerja_id", this.satuankerja_id);
        data.append("satuanKerja_instansi_id", this.instansikerja_id);
        data.append("unor_id", this.selected.id);
        data.append("eselon_id", this.eselon);
        data.append("jabatanFungsional_id", this.jabatanFungsional.id);
        data.append("jabatanFungsionalUmum_id", this.jabatanFungsionalUmum.id);
        data.append("suratKeputusan_pejabat", this.skPejabat);
        data.append("suratKeputusan_nomor", this.skNomor);
        data.append("suratKeputusan_tanggal", this.skTanggal);
        data.append("golongan_id", this.golongan);
        data.append("namaUnor", this.selected.name);
        data.append("namaJabatan", this.namaJabatan);

        const url =
          process.env.VUE_APP_ASN + "jabatan/sync/nip/" + this.get_nip;

        this.http
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then((response) => {
            this.btnLoading = true;
            if (response.data.success) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("berhasilAlert", true);
              refreshView.commit("gagalAlert", false);
              refreshView.commit("success", response.data.success);
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", response.data.success);
            }
            this.reset();
            this.ModalAdd = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              Auth.logout(this.token);
            } else if (error.response) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              this.btnLoading = true;
              this.reset();
              this.ModalAdd = false;
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              console.log("Error", error.message);
              this.btnLoading = true;
              this.reset();
              this.ModalAdd = false;
            }
          });
      } else {
        // console.log('Ini SIMASN')

        const data = new FormData();
        data.append("doc", this.file872);
        data.append("doc", this.file873);
        data.append("jabatan_jenis_id", this.jenisJabatan);
        data.append("jabatan_tmt", this.tmtJabatan);
        data.append("jabatan_tmtPelantikan", this.tmtPelantikan);
        data.append("satuanKerja_instansi_id", this.instansikerja_id);
        data.append("eselon_id", this.eselon);
        data.append("jabatanFungsional_id", this.jabatanFungsional.id);
        data.append("jabatanFungsionalUmum_id", this.jabatanFungsionalUmum.id);
        data.append("suratKeputusan_pejabat", this.skPejabat);
        data.append("suratKeputusan_nomor", this.skNomor);
        data.append("suratKeputusan_tanggal", this.skTanggal);
        data.append("golongan_id", this.golongan);
        data.append("namaUnor", this.namaUnor);
        data.append("namaJabatan", this.namaJabatan);

        const url = process.env.VUE_APP_ASN + "jabatan/nip/" + this.get_nip;

        this.http
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then((response) => {
            this.btnLoading = true;
            if (response.data.success) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("berhasilAlert", true);
              refreshView.commit("gagalAlert", false);
              refreshView.commit("success", response.data.success);
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", response.data.success);
            }
            this.reset();
            this.ModalAdd = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              Auth.logout(this.token);
            } else if (error.response) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              this.btnLoading = true;
              this.reset();
              this.ModalAdd = false;
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              console.log("Error", error.message);
              this.btnLoading = true;
              this.reset();
              this.ModalAdd = false;
            }
          });
      }
    },

    onChange(value) {
      if (value == 1) {
        this.viewEselon = true;
        this.viewFungsional = false;
        this.viewFungsionalumum = false;
        this.viewTmtpelantikan = true;
      } else if (value == 2) {
        this.viewEselon = false;
        this.viewFungsional = true;
        this.viewFungsionalumum = false;
        this.viewTmtpelantikan = true;
        this.tmtPelantikan = "";
      } else if (value == 4) {
        this.viewEselon = false;
        this.viewFungsional = false;
        this.viewFungsionalumum = true;
        this.viewTmtpelantikan = false;
        this.tmtPelantikan = "";
      } else {
        this.dialog = true;
      }
    },

    onChangeJF(value) {
      this.namaJabatan = value.nama;
    },

    onChangeJFU(value) {
      this.namaJabatan = value.nama;
    },

    tutupDialog() {
      this.jenisJabatan = "";
      this.dialog = false;
    },

    closeModal() {
      this.default();
      this.reset();
      this.ModalAdd = false;
    },

    clearUnor() {
      this.$refs.pilihUnor.reset();
    },

    async unorChange(value) {
      const token = await Cookie.get("token");
      const response = await getRef.subUnor(token, value.id);
      this.refUnorsub = response;
    },

    onSatuankerja(value) {
      this.satuankerja_id = value.id;
      this.instansikerja_id = value.instansi.id;
    },

    on872(value) {
      this.file872 = value;
    },

    on873(value) {
      this.file873 = value;
    },
  },
};
</script>
