import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
    editedIndex: -1,
    dataUtama: {
      id: '',
      SAPK: {
        id: ''
      },
      nipBaru: '',
      nipLama: ''
    },
    rwjabatan: {
      id: '',
      sapk: {
        id: ''
      },
      jabatan: {
        jenis: {
          id: '',
          nama: ''
        },
        tmt: '',
        tmtPelantikan: ''
      },
      satuanKerja: {
        id: '',
        nama: '',
        instansi: {
          id: '',
          nama: '',
          jenis: ''
        }
      },
      unor: {
        id: '',
        nama: '',
        atasan: {
          id: '',
          nama: ''
        },
        induk: {
          id: '',
          nama: ''
        }
      },
      eselon: {
        id: '',
        nama: ''
      },
      jabatanFungsional: {
        id: '',
        nama: ''
      },
      jabatanFungsionalUmum: {
        id: '',
        nama: ''
      },
      suratKeputusan: {
        pejabat: '',
        nomor: '',
        tanggal: ''
      },
      golongan: {
        id: '',
        nama: '',
        pangkat: ''
      },
      namaUnor: '',
      namaJabatan: '',
      userId: '',
      NCSISTIME: ''
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },
    editModal(state, value) {
      state.rwjabatan = value
    },
    editDatautama(state, value) {
      state.dataUtama = value
    },
    indexItem(state, value) {
      state.editedIndex = value
    }
  }
})
