import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
    editedIndex: -1,

    dataUtama: {
      id: '',
      SAPK: {
        id: ''
      },
      nipBaru: '',
      nipLama: ''
    },

    data: {
      id: '',
      jenjang_jf: {
        id: '',
        nama: ''
      },
      golongan: {
        id: '',
        nama: ''
      },
      jenis_jf_id: '',
      penjenjangan: '',
      angka_kredit: 0,
      tmt_jabatan: null,
      pejabat_sk: '',
      nomor_sk: '',
      tgl_sk: null,
      doc: '',
      userId: '',
      NCSISTIME: ''
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },

    editModal(state, value) {
      state.data = value
    },

    editDatautama(state, value) {
      state.dataUtama = value
    },

    indexItem(state, value) {
      state.editedIndex = value
    }
  }
})